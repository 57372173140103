<template>
  <div class="container-fluid">
    
    
    <div class="row">
      <div class="col-lg-12">
        <div
          class="d-flex flex-wrap align-items-center justify-content-between mb-3"
        >
          <div class="d-flex align-items-center justify-content-between">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb p-0 mb-0">
                <li class="breadcrumb-item">Car
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Add Car
                </li>
              </ol>
            </nav>
          </div>

          <router-link
          :to="{ path: '/washsubscription/add/' }"
          class="
            btn btn-secondary btn-sm
            d-flex
            align-items-center
            justify-content-between
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
              clip-rule="evenodd"
            />
          </svg>
          <span class="ml-2">Back</span>
        </router-link>
        </div>
      </div>
    </div>


    <div class="row">
      
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <form class="row g-3 date-icon-set-modal">
                  <div class="col-md-4 mb-3">
                    <label
                      for="car-make"
                      class="form-label font-weight-bold text-muted"
                      >Make *</label
                    >
                    <vue-single-select
                      v-model="makeId"
                      :options="vehicleMakes"
                      option-label="name"
                      option-key="id"
                      name="dude"
                      value="id"
                      :getOptionDescription="getCustomDescription"
                      @input="onChangeCarMake($event)"
                    >
                    </vue-single-select>
                   
                    <label
                      v-if="emptyMakeName"
                      for="Text1"
                      class="
                        text-uppercase text-danger
                        col-form-label-sm
                        mt-1
                        ml-1
                      "
                      >Please select car make</label
                    >
                  </div>

                  <div class="col-md-4 mb-3" v-if="makeId != null">
                    <label
                      for="car-model"
                      class="form-label font-weight-bold text-muted"
                      >Model *</label
                    >
                    <vue-single-select
                      v-model="modelID"
                      :options="vehicleModels"
                      option-label="name"
                      option-key="id"
                      name="model"
                      value="id"
                      :getOptionDescription="getCustomDescriptionModels"
                      @input="onChangeCarModel($event)"
                    >
                    </vue-single-select>
                    <label
                      v-if="emptyModelName"
                      for="Text2"
                      class="
                        text-uppercase text-danger
                        col-form-label-sm
                        mt-1
                        ml-1
                      "
                      >Please select car model</label
                    >
                  </div>
                  <div class="col-md-4 mb-3" v-if="modelID != null">
                    <label
                      for="Text3"
                      class="form-label font-weight-bold text-muted"
                      >Year *</label
                    >
                    <vue-single-select
                      v-model="year"
                      :options="years"
                      :getOptionDescription="getCustomDescriptionYears"
                    >
                    </vue-single-select>
                    <label
                      v-if="invalidEmptyYear"
                      for="Text3"
                      class="
                        text-uppercase text-danger
                        col-form-label-sm
                        mt-1
                        ml-1
                      "
                      >Year is not valid</label
                    >
                  </div>
                  <div class="col-md-4 mb-3" v-if="modelID != null">
                    <label for="classification" class="form-label text-muted"
                      >Classification:</label
                    >
                    <input
                      type="text"
                      disabled
                      class="form-control"
                      id="Text2"
                      placeholder="Enter Plate Number"
                      v-model="classification"
                    />
                  </div>

                  <div class="col-md-4 mb-3">
                    <label for="license_no" class="form-label text-muted"
                      >Plate No:</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="Text3"
                      placeholder="Enter Plate Number"
                      v-model="licensePlate"
                    />
                  </div>
                </form>
                <button class="btn btn-success btn-sm" id="add-car" @click="createUserCar">
                  Add Car
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GET_CAR_MAKE, GET_CAR_MODEL } from "../../../graphql/query";
import { CREATE_CAR } from "../../../graphql/mutation";

export default {
  name: "CarAdd",
  data() {
    return {
      vehicleMakes: [],
      makeId: null,
      vehicleModels: [],
      modelID: null,
      result: [],
      start: null,
      end: null,
      classification: "",
      year: null,
      licensePlate: "",
      userId: "",
      vin: null,
      errors: [],
      emptyMakeName: false,
      emptyModelName: false,
      invalidEmptyYear: false,
      years: [],
      registrationRenewalDate: null,
      insuranceRenewalDate: null
    };
  },
  apollo: {
    vehicleMakes: {
      query: GET_CAR_MAKE,
    },
  },
  props: ["number"],

  methods: {
    getCustomDescription(opt) {
      return opt.name;
    },
    getCustomDescriptionModels(opt) {
      return opt.name;
    },
    getCustomDescriptionYears(opt) {
      return opt;
    },

    onContextRegistrationRenewalDate(ctx) {
      this.registrationRenewalDate = ctx.selectedYMD;
    },

    onContextInsuranceRenewalDate(ctx) {
      this.insuranceRenewalDate = ctx.selectedYMD;
    },

    async onChangeCarMake(selection) {
      if (selection == null) {
        this.modelID = null;
      } else {
        this.makeId = selection;
        this.emptyMakeName = false;
        this.$apollo
          .query({
            query: GET_CAR_MODEL,
            variables: {
              makeId: this.makeId.id,
            },
          })
          .then((data) => {
            this.vehicleModels = data.data.vehicleModels;
          });
      }
    },

    onChangeCarModel(event) {
      this.modelID = event;
      this.emptyModelName = false;
      this.vehicleModels.filter((data) => {
        if (data.id === this.modelID.id) {
          this.years = data.years;
          this.classification = data.classification;
          return this.result;
        }
      });
    },
    handleYear() {
      this.invalidEmptyYear = false;
    },
    createUserCar() {
     
      if (this.makeId === null) {
        return (this.emptyMakeName = true);
      } else if (this.modelID === null) {
        return (this.emptyModelName = true);
      } else if (this.year === null) {
        return (this.invalidEmptyYear = true);
      // }else if (this.registrationRenewalDate === null) {
      //   return (this.invalidEmptyRegistrationRenewal = true);
      // }else if (this.insuranceRenewalDate === null) {
      //   return (this.invalidEmptyInsuranceRenewal = true);
      } else {
        document.getElementById("add-car").disabled = true;
        this.$apollo
          .mutate({
            mutation: CREATE_CAR,
            variables: {
              classification: this.classification,
              model: this.modelID.id,
              year: this.year,
              userId: this.number,
              licensePlate: this.licensePlate,
              vin: this.vin,
              registrationRenewalAt:this.registrationRenewalDate ? this.registrationRenewalDate : null,
              insuranceRenewalAt:this.insuranceRenewalDate ? this.insuranceRenewalDate : null
            },
          })
          .then((data) => {
            if (data.data.bwsDashboardUserCarCreate.errors.length === 0) {
               localStorage.setItem('customer-car', data.data.bwsDashboardUserCarCreate.car.id);
              this.$router.go(-1);
              document.getElementById("add-car").disabled = false;
              // this.$router.push({ path: "/customerview/" + this.number });
            } else {
              document.getElementById("add-car").disabled = false;
              this.errors = data.data.bwsDashboardUserCarCreate.errors;
              this.errors.map((err) => {
                if (err.message === "Year not in model.years.") {
                  return (this.invalidEmptyYear = true);
                }
              });
            }
          });
      }
    },
  },
};
</script>
<style>
.b-toast-success.b-toast-solid .toast.toast-box,
.b-toast-danger.b-toast-solid .toast.toast-box {
  padding: 10px;
  border-radius: 3px;
}
</style>
